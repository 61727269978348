<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div id="report" class="card-body">
                      <div class="container-fluid">
                        <div style="width: 80px;">
                          <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                        </div>
                        <div class="headerreport">
                          <div class="row">
                            {{ companydata.cmp_nmbre }}
                          </div>
                          <div class="row">
                            {{ fecha }}
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="card-title">
                        <h1 class="titlereport">Reporte de Usuario con Seguimientos</h1>
                        <h5 class="subtitle">Usuario: {{ usu_nmbre }}</h5>
                        <h5 class="subtitle">{{ rango }}</h5>
                      </div>
                      <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                          <table id="reporteSegDocs" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                  <tr>
                                    <th>Clase documento</th>
                                    <th>Año</th>
                                    <th>Consecutivo</th>
                                    <th>Fecha Radicación</th>
                                    <th class="entidad">Entidad</th>
                                    <th class="asunto">Asunto</th>
                                    <th class="dependencia">Dependencia</th>
                                    <th class="serie">Serie</th>
                                    <th>Pendiente respuesta</th>
                                    <th>Último seguimiento</th>
                                    <th>Fecha último seguimiento</th>
                                    <th>Tiempo restante</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in items" class="rowstyle">
                                    <td>{{ item.tcr_nmbre }}</td>
                                    <td>{{ item.dcm_ano }}</td>
                                    <td>{{ item.dcm_cdgo }}</td>
                                    <td>{{ item.dcm_fcharad | formatDate }}</td>
                                    <td>{{ item.dcm_entidad }}</td>
                                    <td>{{ item.dcm_asunto }}</td>
                                    <td>{{ item.dep_nmbre }}</td>
                                    <td>{{ item.ser_nmbre }}</td>
                                    <td>{{ item.dcm_rspsta | formatBoolean }}</td>
                                    <td>{{ item.ParaUsuarioNombre }}</td>
                                    <td>{{ item.FechaSeguimiento | formatDate }}</td>
                                    <td>{{ item.restante }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <br>
                      <div v-if="showProgress" style="text-align:center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="blue"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                  </div>
                  <br>
                  <div class="row justify-content-around" align="center">
                    <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
                    <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDate, formatBoolean } from './../../plugins/filters';

import print from 'print-js';
import XLSX from 'xlsx';
import { tiempoUtilizado } from '../../utils';

export default {
  components: {
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      tpocors: [],
      dependencias: [],
      series: [],
      debug: null,
      fecha: null,
      rango: '',
      usu_nmbre: '',
      dcm_fcharadini: '',
      dcm_fcharadfin: '',
      showProgress: false,
      dnl: []
    }
  },
  filters: {
    formatDate, formatBoolean
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');

    this.fetchTpocor(err => {
      if (err){
        alert('Error al consultar las clases de documentos.');
      } else {
        this.fetchDependencia(err => {
          if (err){
            alert('Error al consultar las dependencias.');
          } else {
            this.fetchSerie(err => {
              if (err){
                alert('Error al consultar las series.');
              } else {
                this.fetchDianol(err => {
                  if (err){
                    alert('Error al consultar los días no laborales.');
                  } else {
                    this.debug = this.$route.params;
                    this.usu_nmbre = this.$route.params.usu_nmbre;
                    this.dcm_fcharadini = this.$route.params.query.dcm_fcharadini;
                    this.dcm_fcharadfin = this.$route.params.query.dcm_fcharadfin;

                    this.rango = "Desde: " + moment(this.dcm_fcharadini).format('DD/MMM/YYYY') + " --- Hasta: " + moment(this.dcm_fcharadfin).format('DD/MMM/YYYY');

                    this.searchItems(this.$route.params.query);
                  }
                });
              }
            });
          }
        });
      }
    });
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchTpocor(callback){
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDependencia(callback){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchSerie(callback){
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDianol(callback){
      var p = {};
      p.cmp_id = this.$store.state.company;
      p.inicio = this.$route.params.query.dcm_fcharadini;
      p.fin = this.$route.params.query.dcm_fcharadfin;

      let uri = '/dianols/rango';
      this.axios.post(uri, p)
      .then((response) => {
        this.dnl = response.data;
        return callback(false);
      })
      .catch((err) => {
        return callback(true);
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/segdocs';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;
          this.items = [];

          for (var i = 0; i < list.length; i++){
            for (var j = 0; j < this.tpocors.length; j++){
              if (list[i].tcr_id == this.tpocors[j]._id){
                list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                j = this.tpocors.length;
              }
            }

            for (var j = 0; j < this.dependencias.length; j++){
              if (list[i].dep_id == this.dependencias[j]._id){
                list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                j = this.dependencias.length;
              }
            }

            for (var j = 0; j < this.series.length; j++){
              if (list[i].ser_id == this.series[j]._id){
                list[i].ser_nmbre = this.series[j].ser_nmbre;
                if (list[i].dcm_tres == undefined){
                  list[i].dcm_tres = this.series[j].ser_tres;
                }
                j = this.series.length;
              }
            }

            if (list[i].dcm_rspsta){
              list[i].restante = parseInt(list[i].dcm_tres) - tiempoUtilizado(list[i].dcm_fcharad, new Date(), this.dnl);
            } else {
              list[i].restante = '';
            }

            /*
            if (list[i].dcmnt_docs.length > 0){
              for (var j = 0; j < this.tpocors.length; j++){
                if (list[i].dcmnt_docs[0].tcr_id == this.tpocors[j]._id){
                  list[i].respuesta = this.tpocors[j].tcr_cdgo + '-' + list[i].dcmnt_docs[0].dcm_ano + '-' + list[i].dcmnt_docs[0].dcm_cdgo;
                  j = this.tpocors.length;
                }
              }
            }
            */

            if (list[i].usuario_docs.length > 0){
              list[i].ParaUsuarioNombre = list[i].usuario_docs[0].usu_nmbre + ' ' + list[i].usuario_docs[0].usu_aplldo;
            }

            if (list[i].seguimientos.length > 0){
              list[i].FechaSeguimiento = list[i].seguimientos[0].Fecha;
            }

            this.items.push(list[i]);

          }
          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteSegDocs';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.dcm_ano = this.items[i].dcm_ano;
        item.dcm_cdgo = this.items[i].dcm_cdgo;
        item.dcm_fcharad = moment(this.items[i].dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
        item.dcm_entidad = this.items[i].dcm_entidad;
        item.dcm_asunto = this.items[i].dcm_asunto;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.dcm_rspsta = this.items[i].dcm_rspsta ? 'Si' : 'No';
        item.ParaUsuarioNombre = this.items[i].ParaUsuarioNombre;
        if (this.items[i].FechaSeguimiento){
          item.FechaSeguimiento = moment(this.items[i].FechaSeguimiento).format('DD/MMM/YYYY hh:mm A');
        } else {
          item.FechaSeguimiento = '';
        }
        item.restante = this.items[i].restante;

        report.push(item);
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de Usuario con Seguimientos"],
      	["Usuario: " + this.usu_nmbre],
        [this.rango],
        [],
        ["Año", "Consecutivo", "Fecha Radicación", "Entidad", "Asunto", "Dependencia", "Serie", "Pendiente respuesta", "Último seguimiento", "Fecha último seguimiento", "Tiempo restante"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A10" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/report.css';
</style>
